import { platform } from "@/platform";
import { scrapeEmptyFields } from "@/utils/dataCleaning";
import { fileTypeFromMimeType } from "@/utils/fileTypeUtils";
import { StorageAccessLevel } from "@aws-amplify/core";
import { uploadData } from "@aws-amplify/storage";
import { Amplify } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";
import { amplifyAuthOverride } from "./../hooks/user/utils";

export const configureAmplifyS3 = (s3BucketName: string) => {
    const resourcesConfig = {
        ...Amplify.getConfig(),
        Storage: {
            S3: {
                bucket: s3BucketName,
                region: "us-east-1",
            },
        },
    };

    const libraryOptions = {
        Storage: {
            S3: {
                prefixResolver: async () => "",
            },
        },
        ...amplifyAuthOverride,
    };

    Amplify.configure(resourcesConfig, libraryOptions);
};

export const uploadPictureToS3 = async (blob: Blob | ArrayBuffer, s3BucketName: string, contentType: string) => {
    configureAmplifyS3(s3BucketName);
    const fileName = `${uuidv4()}.${fileTypeFromMimeType(contentType)}`;

    try {
        const uploadJob = uploadData({
            key: fileName,
            data: blob,
            options: {
                accessLevel: "guest" as StorageAccessLevel,
                contentType,
            },
        });

        await uploadJob.result;
    } catch (error) {
        const { report } = await platform.analytics.logging();
        report(error, "uploadPictureToS3", { s3BucketName, contentType });
        throw error;
    }

    return `https://${s3BucketName}.s3.amazonaws.com/${fileName}`;
};

export const sanitizeFilename = (filename: string) => {
    return (
        filename
            .normalize("NFD") // Normalize the string to decompose characters like ä to a + diacritic mark
            // biome-ignore lint: suspicious/noMisleadingCharacterClass
            .replace(/[\u0300-\u036f]/g, "") // Remove diacritical marks
            .replace(/[^A-Za-z0-9_.-]/g, "_")
    ); // Replace non-alphanumeric, non . _ - characters with an underscore
};

export const uploadMediaToS3 = async ({
    userId,
    folderId,
    classId,
    media,
    s3BucketName,
    progressCallback,
    setCurrentUploadJob,
    fileName,
    contentType,
    remainingTimeCallback,
    title,
}: {
    userId: string;
    folderId?: string;
    classId?: string;
    media: Blob | ArrayBuffer;
    s3BucketName: string;
    progressCallback?: ({ transferredBytes, totalBytes }: { transferredBytes: number; totalBytes: number }) => void;
    setCurrentUploadJob?: (job: UploadDataOutput) => void;
    fileName?: string;
    contentType: string;
    remainingTimeCallback?: (remainingTime: number) => void;
    title?: string;
}) => {
    configureAmplifyS3(s3BucketName);

    const startTime = Date.now();

    try {
        const uploadJob = uploadData({
            key: fileName,
            data: media,
            options: {
                metadata: scrapeEmptyFields({
                    userId,
                    folderId,
                    classId,
                    title: sanitizeFilename(title),
                }),
                accessLevel: "guest" as StorageAccessLevel,
                contentType,
                onProgress: ({ transferredBytes, totalBytes }) => {
                    if (progressCallback) {
                        progressCallback({ transferredBytes, totalBytes });
                    }

                    const currentTime = Date.now();
                    const elapsedTimeInSeconds = (currentTime - startTime) / 1000;
                    const uploadSpeed = transferredBytes / elapsedTimeInSeconds;
                    const remainingBytes = totalBytes - transferredBytes;
                    const remainingTimeInSeconds = remainingBytes / uploadSpeed;

                    if (remainingTimeCallback) {
                        remainingTimeCallback(Math.floor(remainingTimeInSeconds));
                    }
                },
            },
        });

        setCurrentUploadJob(uploadJob);

        return await uploadJob.result;
    } catch (e) {
        const { report } = await platform.analytics.logging();
        const mixpanel = await platform.analytics.mixpanel();
        report(e, "uploadMediaToS3", { s3BucketName, fileName, userId, folderId });
        mixpanel.track("uploadMediaToS3-failed", { s3BucketName, fileName, userId, folderId, error: e });
        if (e.name === "RequestTimeTooSkewed") {
            const toast = await platform.toast();
            toast.error(
                "Your computer's time is not in sync with the server. For security reasons, your upload could not be completed. Please change your computer's time to be set automatically and try again.",
                {
                    duration: 10_000,
                }
            );
        }
        throw new Error("UploadFailed : " + e + s3BucketName + fileName);
    }
};

export const cancelOngoingS3Upload = async ({ uploadJob, setUploadJob }) => {
    try {
        uploadJob.cancel();
        setUploadJob(null);
        return true;
    } catch (e) {
        const { report } = await platform.analytics.logging();
        report(e, "cancelOngoingS3Upload", { uploadJob });
        return false;
    }
};

export const uploadRecordedAudioToS3 = async (blob: Blob | ArrayBuffer, s3BucketName: string, contentType: string) => {
    configureAmplifyS3(s3BucketName);
    const fileName = `${uuidv4()}.${fileTypeFromMimeType(contentType)}`;

    try {
        const uploadJob = uploadData({
            key: fileName,
            data: blob,
            options: {
                accessLevel: "guest" as StorageAccessLevel,
                contentType,
            },
        });

        await uploadJob.result;
    } catch (error) {
        const { report } = await platform.analytics.logging();
        report(error, "uploadRecordedAudioToS3", { s3BucketName, contentType });
        throw error;
    }

    return `https://${s3BucketName}.s3.amazonaws.com/${fileName}`;
};
