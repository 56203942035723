import { getHtmlFromContent } from "@knowt/editor/helpers/getHtmlFromContent";
import { mutate } from "swr";
import { v4 as uuidv4 } from "uuid";
import { listFlashcardSetIdByNote } from "@/graphql/customQueries";
import {
    callBatchUpdateFlashcard,
    callCreateFlashcardSet,
    callDeleteFlashcardSet,
    callUpdateFlashcardSet,
    fetchFlashcardSet,
    flashcardSetWithFlashcardsData,
} from "@/hooks/flashcards/graphqlUtils";
import { resolveNoteSWRKey, saveNote, SHARED_NOTE_FLASHCARD_SET_KEYS } from "@/hooks/notes/utils";
import { getSWRValue, safeLocalMutate, StableSWRKeys } from "@/hooks/swr/swr";
import { platform } from "@/platform";
import { Class, Flashcard, FlashcardSet, FlashcardSide, Note, UserDetails } from "@knowt/syncing/graphql/schema";
import { listData } from "@/utils/client/graphql";
import { asyncFilter, chunkArray } from "@/utils/arrayUtils";
import { deepScrapeEmptyFields, objectWithout, pick } from "@/utils/dataCleaning";
import { fromEntries } from "@/utils/genericUtils";
import { ListFlashcardSetByUserNoContentQuery } from "@/graphql/customSchema";
import { resolveClassSWRKey, updateClass } from "@/hooks/classes/utils";
import { callGetClass } from "@/hooks/classes/graphqlUtils";
import { fetchBookmarks } from "@/hooks/bookmarks/utils";

import { now } from "@/utils/dateTimeUtils";
import { getPlainTextFromContent } from "@knowt/editor/helpers/getPlainTextFromContent";

const FLASHCARD_GOOD_QUALITY = 5;

export const resolveFlashcardSetsSWRKey = ({ userId, isEnabled = true }: { userId: string; isEnabled?: boolean }) => {
    if (!isEnabled) return null;
    return ["flashcard-sets", userId];
};

export const resolveFolderFlashcardSetsSWRKey = ({
    folderId,
    isEnabled = true,
}: {
    folderId: string;
    isEnabled?: boolean;
}) => {
    return isEnabled && ["folder-flashcard-sets", folderId];
};

export const resolveClassFlashcardSetsSWRKey = ({
    classId,
    isEnabled = true,
}: {
    classId: string;
    isEnabled?: boolean;
}) => {
    return isEnabled && ["class-flashcard-sets", classId];
};

export const resolveFlashcardSetSWRKey = ({
    flashcardSetId,
    isEnabled = true,
    raw = false,
    loadingFlashcardSetId,
}: {
    flashcardSetId?: string;
    loadingFlashcardSetId?: boolean;
    isEnabled?: boolean;
    raw?: boolean;
}) => {
    return !loadingFlashcardSetId && isEnabled ? ["flashcard-set", flashcardSetId, ...(raw ? [raw] : [])] : null;
};

export const MIN_FLASHCARDS_COUNT = 4;

export const completeToMinimumFlashcardsCount = async (
    flashcards: Omit<Flashcard, "flashcardSetId" | "flashcardId" | "userId">[]
) => {
    const flashcardsToAdd = await Promise.all(
        Array.from({ length: MIN_FLASHCARDS_COUNT - flashcards.length }, () => makeNewFlashcard())
    );

    return [...flashcards, ...flashcardsToAdd];
};

export const makeNoteFlashcardSet = async (
    note: Note,
    initialFlashcards: Omit<Flashcard, "flashcardSetId" | "flashcardId" | "userId">[],
    overrides: Partial<Omit<FlashcardSet, "flashcards">> = {}
): Promise<FlashcardSet> => {
    const { userId, noteId, classId } = note;

    const { stripedFlashcardSet, flashcards } = separateFlashcardSetFlashcards(
        deepScrapeEmptyFields(
            makeCreateFlashcardSetInitialInput({
                userId,
                flashcards: await completeToMinimumFlashcardsCount(initialFlashcards),
                overrides: {
                    noteId,
                    classId,
                    draft: true,
                    title: note.title,
                    public: note.public,
                    password: note.password,
                    mediaId: note.mediaId || undefined,
                    ...overrides,
                },
            })
        )
    );

    const createdFlashcardSet = await callCreateFlashcardSet(stripedFlashcardSet);

    const createdFlashcards = await callBatchUpdateFlashcard({
        userId,
        flashcardSetId: createdFlashcardSet.flashcardSetId,
        items: flashcards.map(flashcard => ({
            ...flashcard,
            userId,
            flashcardSetId: createdFlashcardSet.flashcardSetId,
        })),
    });

    await saveNote({ noteId: note.noteId, userId, flashcardSetId: createdFlashcardSet.flashcardSetId });

    const result = flashcardSetWithFlashcardsData(
        createdFlashcardSet,
        fromEntries(withHtmlFields({ flashcards: createdFlashcards }).map(f => [f.flashcardId, f]))
    );

    await mutate(resolveFlashcardSetSWRKey({ flashcardSetId: result.flashcardSetId }), result, {
        revalidate: false,
    });

    if (result.classId) {
        // TODO: why is this needed?
        mutate(resolveClassSWRKey({ classId: result.classId }));
    }

    return result;
};

const separateFlashcardSetFlashcards = (flashcardSet: FlashcardSet) => {
    const flashcards = flashcardSet.flashcards;
    const stripedFlashcardSet = stripBaseFlashcardSet(flashcardSet);
    return { stripedFlashcardSet, flashcards };
};

export const stripBaseFlashcardSet = (partialFlashcardSet: Partial<FlashcardSet>) => {
    const result = { ...partialFlashcardSet };

    if (result.flashcards) {
        result.flashcards = result.flashcards.map(fullFlashcardToBaseFlashcardSetFlashcard);
        result.size = result.flashcards.length;
    }

    return result;
};

export const generateFlashcards = (pairs: { term: string; definition: string }[]): Flashcard[] => {
    return pairs.map(({ term, definition }) =>
        makeNewFlashcard({
            term,
            definition,
        })
    );
};

export const makeStandaloneFlashcardSet = async ({
    userId,
    flashcards: initialFlashcards = [],
    ...overrides
}: {
    userId: string | undefined;
    flashcards?: Flashcard[];
    // from chrome extension. unused on web or mobile
    overrideClient?: any;
} & Partial<FlashcardSet>): Promise<FlashcardSet> => {
    if (!userId) return null;

    const { stripedFlashcardSet, flashcards } = separateFlashcardSetFlashcards(
        deepScrapeEmptyFields(
            makeCreateFlashcardSetInitialInput({
                userId,
                flashcards: await completeToMinimumFlashcardsCount(initialFlashcards),
                overrides: { draft: true, ...overrides },
            })
        )
    );

    const flashcardsWithHtmlFields = withHtmlFields({ flashcards });

    const createdFlashcardSet = await callCreateFlashcardSet(stripedFlashcardSet);

    await Promise.all(
        chunkArray(flashcardsWithHtmlFields, 75).map(
            async chunk =>
                await callBatchUpdateFlashcard({
                    userId,
                    flashcardSetId: createdFlashcardSet.flashcardSetId,
                    items: chunk.map((flashcard: Flashcard) => ({
                        ...flashcard,
                        userId,
                        flashcardSetId: createdFlashcardSet.flashcardSetId,
                    })),
                })
        )
    ).catch(async error => {
        await deleteFlashcardSets({
            flashcardSetIds: [{ userId, flashcardSetId: createdFlashcardSet.flashcardSetId }],
        });
        const { report } = await platform.analytics.logging();
        report(error, "makeStandaloneFlashcardSet", {});
        throw error;
    });

    const result = flashcardSetWithFlashcardsData(
        createdFlashcardSet,
        fromEntries(flashcardsWithHtmlFields.map(f => [f.flashcardId, f]))
    );

    await mutate(resolveFlashcardSetSWRKey({ flashcardSetId: result.flashcardSetId }), result, {
        revalidate: false,
    });

    await addStandaloneFlashcardSetToTheCache(result);

    if (result.classId) {
        // TODO: why is this needed?
        mutate(resolveClassSWRKey({ classId: result.classId }));
    }

    return result;
};

export const scrapeEmptyFlashcards = async (flashcards: Flashcard[]): Promise<Flashcard[]> => {
    const nonEmptyFlashcards = await asyncFilter(flashcards, async flashcard => {
        const isEmpty = await isEmptyFlashcard(flashcard);
        return !isEmpty;
    });

    return nonEmptyFlashcards.map(flashcard => ({
        ...flashcard,
        term: flashcard.term ?? "",
        definition: flashcard.definition ?? "",
    }));
};

export const isEmptyFlashcard = async (flashcard: Flashcard) => {
    return (
        (await isFlashcardContentEmpty(flashcard.term)) &&
        (await isFlashcardContentEmpty(flashcard.definition)) &&
        !flashcard.image &&
        !flashcard.secondaryImage
    );
};

export const isFlashcardContentEmpty = async (content: string) => {
    return getPlainTextFromContent({ content, type: "flashcard" }).trim() === "" && !content?.includes("<iframe");
};

export const fullFlashcardToBaseFlashcardSetFlashcard = (flashcard: Flashcard) => {
    return pick(flashcard, "flashcardId");
};

const makeCreateFlashcardSetInitialInput = ({
    userId,
    flashcards,
    overrides,
}: {
    userId: string;
    flashcards: Omit<Flashcard, "flashcardId" | "flashcardSetId">[];
    overrides?: Partial<FlashcardSet>;
}) => {
    const flashcardSetId = uuidv4();

    return {
        userId,
        flashcardSetId,
        flashcards: flashcards.map(flashcard => ({
            flashcardId: uuidv4(),
            ...flashcard,
            flashcardSetId,
        })),
        position: 0,
        trash: false,
        draft: false,
        classPublic: false,
        sort: now(), // This field is used to differentiate between base sets and study sets
        created: now(),
        updated: now(),
        ...overrides,
    };
};

export const makeNewFlashcard = (overrides?: Partial<Flashcard>): Flashcard => {
    const term = overrides?.term ?? "";
    const definition = overrides?.definition ?? "";

    return {
        flashcardId: uuidv4(),
        term,
        definition,
        trash: false,
        edited: false,
        disabled: false,
        quality: FLASHCARD_GOOD_QUALITY,
        created: String(now()),
        updated: String(now()),
        ...overrides,
    };
};

export const fetchFlashcardSetsMetaDataByNote = async (noteId: string) => {
    return (await listData({
        listQuery: listFlashcardSetIdByNote,
        input: { noteId },
        queryName: "listFlashcardSetByNote",
    })) as ListFlashcardSetByUserNoContentQuery["listFlashcardSetByUser"]["items"];
};

export const addStandaloneFlashcardSetToTheCache = async (newFlashcardSet: FlashcardSet) => {
    const { userId, folderId, classId } = newFlashcardSet;
    const updater = (oldFlashcardSets: Record<string, FlashcardSet>) => addToCache(oldFlashcardSets, [newFlashcardSet]);

    const swrKeys = [
        resolveFlashcardSetsSWRKey({ userId }),
        ...(classId ? [resolveClassFlashcardSetsSWRKey({ classId })] : []),
        ...(folderId ? [resolveFolderFlashcardSetsSWRKey({ folderId })] : []),
    ];

    await Promise.all(swrKeys.map(swrKey => safeLocalMutate(swrKey, updater)));
};

export const moveFlashcardSets = async ({
    flashcardSetIds,
    sourceFolderId,
    destinationFolderId,
    sourceClassId,
    destinationClassId,
    isPublic,
    sharedSections,
}: {
    flashcardSetIds: { userId: string; flashcardSetId: string }[];
    sourceFolderId?: string | null;
    destinationFolderId: string | null;
    sourceClassId?: string | null;
    destinationClassId?: string | null;
    isPublic?: boolean;
    sharedSections?: string[] | null;
}) => {
    await genericUpdateFlashcardSets({
        flashcardSetIds,
        sourceFolderId,
        destinationFolderId,
        sourceClassId,
        destinationClassId,
        updatedFields: {
            folderId: destinationFolderId,
            classId: destinationClassId,
            sections: sharedSections,
            updated: now(),
            ...(isPublic === undefined ? {} : { public: isPublic }),
            ...(sourceClassId !== destinationClassId ? { addedAt: now().toString() } : {}),
            ...(destinationClassId ? { password: null } : {}),
        },
    });
};

export const trashFlashcardSets = async ({
    flashcardSetIds,
    sourceFolderId,
    sourceClassId,
}: {
    flashcardSetIds: {
        userId: string;
        flashcardSetId: string;
    }[];
    sourceFolderId: string | null;
    sourceClassId?: string | null;
}) => {
    await updateFlashcardSetsTrashState({
        flashcardSetIds,
        sourceFolderId,
        sourceClassId,
        inTrash: true,
        removeFromFolder: true,
        removeFromClass: true,
    });
};

export const restoreFlashcardSets = async ({
    flashcardSetIds,
    sourceFolderId,
}: {
    flashcardSetIds: {
        userId: string;
        flashcardSetId: string;
    }[];
    sourceFolderId: string | null;
}) => {
    await updateFlashcardSetsTrashState({
        flashcardSetIds,
        sourceFolderId,
        inTrash: false,
        removeFromFolder: true,
    });
};

const updateFlashcardSetsTrashState = async ({
    flashcardSetIds,
    sourceFolderId,
    sourceClassId,
    inTrash,
    removeFromFolder,
    removeFromClass,
}: {
    flashcardSetIds: {
        userId: string;
        flashcardSetId: string;
    }[];
    sourceFolderId: string | null;
    sourceClassId?: string | null;
    inTrash: boolean;
    removeFromFolder: boolean;
    removeFromClass?: boolean;
}) => {
    await genericUpdateFlashcardSets({
        flashcardSetIds,
        sourceFolderId,
        sourceClassId,
        destinationFolderId: removeFromFolder ? null : sourceFolderId,
        updatedFields: {
            trash: inTrash,
            ...(removeFromFolder && { folderId: null }),
            ...(removeFromClass && { classId: null }),
        },
    });
};

export const updateFlashcardSetsPublicState = async ({
    flashcardSetIds,
    sourceFolderId,
    isPublic,
    password = null,
}: {
    flashcardSetIds: {
        userId: string;
        flashcardSetId: string;
    }[];
    sourceFolderId: string | null;
    isPublic: boolean;
    password?: string | null;
}) => {
    await genericUpdateFlashcardSets({
        flashcardSetIds,
        sourceFolderId,
        destinationFolderId: sourceFolderId, // we're not changing the folder
        updatedFields: { public: isPublic, password },
    });
};

export const deleteFlashcardSets = async ({
    flashcardSetIds,
}: {
    flashcardSetIds: {
        userId: string;
        flashcardSetId: string;
    }[];
}) => {
    const currentUser = await getSWRValue(StableSWRKeys.USER);
    const currentUserId = currentUser.user.ID;

    await safeLocalMutate(resolveFlashcardSetsSWRKey({ userId: currentUserId }), oldFlashcardSets =>
        objectWithout(oldFlashcardSets, ...flashcardSetIds.map(({ flashcardSetId }) => flashcardSetId))
    );

    await Promise.all(
        flashcardSetIds.map(({ flashcardSetId }) =>
            mutate(resolveFlashcardSetSWRKey({ flashcardSetId }), undefined, { revalidate: false })
        )
    );

    try {
        await Promise.all(
            flashcardSetIds.map(({ userId, flashcardSetId }) => callDeleteFlashcardSet({ flashcardSetId, userId }))
        );
    } catch {
        const toast = await platform.toast();
        toast.error("Failed to delete flashcard sets. Please refresh the page.");
    }
};

export const genericUpdateFlashcardSets = async ({
    flashcardSetIds,
    sourceFolderId,
    destinationFolderId,
    sourceClassId,
    destinationClassId,
    updatedFields,
}: {
    flashcardSetIds: {
        userId: string;
        flashcardSetId: string;
    }[];
    sourceFolderId?: string | null;
    destinationFolderId?: string | null;
    sourceClassId?: string | null;
    destinationClassId?: string | null;
    updatedFields: Partial<FlashcardSet>;
}) => {
    const currentUser = await getSWRValue(StableSWRKeys.USER);
    const currentUserId = currentUser.user.ID;

    await Promise.all([
        // mutate the source folder cache for faster UX
        mutate(
            resolveFolderFlashcardSetsSWRKey({ folderId: sourceFolderId }),
            async oldFlashcardSets =>
                fromEntries(
                    Object.values(
                        updateCache(
                            oldFlashcardSets,
                            flashcardSetIds.map(({ flashcardSetId }) => flashcardSetId),
                            updatedFields
                        )
                    )
                        .filter(flashcardSet => flashcardSet.folderId === sourceFolderId)
                        .map(flashcardSet => [flashcardSet.flashcardSetId, flashcardSet])
                ),
            { revalidate: false }
        ),
        mutate(
            resolveClassFlashcardSetsSWRKey({ classId: sourceClassId }),
            async oldFlashcardSets =>
                updateCache(
                    oldFlashcardSets,
                    flashcardSetIds.map(({ flashcardSetId }) => flashcardSetId),
                    updatedFields
                ),
            { revalidate: false }
        ),
        ...flashcardSetIds.map(({ flashcardSetId }) =>
            mutate(
                resolveFlashcardSetSWRKey({ flashcardSetId }),
                async oldFlashcardSet => (oldFlashcardSet ? { ...oldFlashcardSet, ...updatedFields } : null),
                { revalidate: false }
            )
        ),
    ]);

    try {
        const updatedFlashcardSets = await Promise.all(
            flashcardSetIds.map(({ userId, flashcardSetId }) =>
                saveFlashcardSet({ flashcardSetId, userId, updates: updatedFields })
            )
        );

        if (destinationFolderId !== sourceFolderId) {
            await safeLocalMutate(
                resolveFolderFlashcardSetsSWRKey({ folderId: destinationFolderId }),
                oldFlashcardSets => addToCache(oldFlashcardSets, updatedFlashcardSets)
            );
        }

        if (destinationClassId !== sourceClassId) {
            await safeLocalMutate(resolveClassFlashcardSetsSWRKey({ classId: destinationClassId }), oldFlashcardSets =>
                addToCache(oldFlashcardSets, updatedFlashcardSets)
            );

            if (sourceClassId) {
                const sourceClass =
                    (await mutate(resolveClassSWRKey({ classId: sourceClassId }), (oldData: Class) => oldData, {
                        revalidate: false,
                    })) ?? (await callGetClass({ classId: sourceClassId }));

                const updatedPinned = sourceClass.pinned.filter(
                    itemId => !flashcardSetIds.find(({ flashcardSetId }) => itemId === flashcardSetId)
                );

                if (updatedPinned.length !== sourceClass.pinned.length) {
                    await updateClass({ classId: sourceClassId, pinned: updatedPinned }, { ID: currentUserId });
                }
            }

            if (destinationClassId) {
                const bookmarks = await fetchBookmarks({ userId: currentUserId });

                const bookmarkedFlashcardSets = flashcardSetIds
                    .filter(({ flashcardSetId }) => bookmarks.some(b => b.ID === flashcardSetId))
                    .map(({ flashcardSetId }) => flashcardSetId);

                if (bookmarkedFlashcardSets.length) {
                    const destinationClass =
                        (await mutate(
                            resolveClassSWRKey({ classId: destinationClassId }),
                            (oldData: Class) => oldData,
                            {
                                revalidate: false,
                            }
                        )) ?? (await callGetClass({ classId: destinationClassId }));

                    const updatedPinned = [...destinationClass.pinned, ...bookmarkedFlashcardSets];
                    await updateClass({ classId: destinationClassId, pinned: updatedPinned }, { ID: currentUserId });
                }
            }
        }
    } catch {
        const toast = await platform.toast();
        toast.error("Failed to update flashcard sets. Please refresh the page.");
    }
};

export const saveFlashcardSet = async ({
    flashcardSetId,
    userId,
    updates,
}: {
    flashcardSetId: string;
    userId: string;
    updates: Partial<FlashcardSet>;
}) => {
    // This is done since we fetch flashcard sets separately, so we can't just put the updated flashcard set in the cache directly
    const newFlashcardSet = await mutate(
        resolveFlashcardSetSWRKey({ flashcardSetId }),
        async (oldFlashcardSet: FlashcardSet) => {
            if (!oldFlashcardSet) oldFlashcardSet = await fetchFlashcardSet({ flashcardSetId });
            return { ...oldFlashcardSet, ...updates };
        },
        { revalidate: false }
    );

    const updatedFields = pick(
        stripBaseFlashcardSet(newFlashcardSet),
        "userId",
        ...(Object.keys(updates) as (keyof FlashcardSet)[])
    );

    await callUpdateFlashcardSet(flashcardSetId, updatedFields);
    await addStandaloneFlashcardSetToTheCache(newFlashcardSet);

    if (
        newFlashcardSet.flashcardSetId &&
        Object.keys(updates).some(key => SHARED_NOTE_FLASHCARD_SET_KEYS.includes(key))
    ) {
        // backend will update the SHARED_NOTE_FLASHCARD_SET_KEYS keys of the note
        // to follow those of the note, so we need to revalidate the cached note:
        await mutate(resolveNoteSWRKey({ noteId: newFlashcardSet.noteId, userId }));
    }

    return newFlashcardSet;
};

const updateCache = (
    cache: Record<string, FlashcardSet>,
    flashcardSetIds: string[],
    updatedFields: Partial<FlashcardSet>
) => {
    const newCache = { ...cache };
    for (const flashcardSetId of flashcardSetIds) {
        newCache[flashcardSetId] = { ...newCache[flashcardSetId], ...updatedFields };
    }
    return newCache;
};

const addToCache = (cache: Record<string, FlashcardSet>, flashcardSets: FlashcardSet[]) => {
    const newCache = { ...cache };
    for (const flashcardSet of flashcardSets) {
        newCache[flashcardSet.flashcardSetId] = flashcardSet;
    }
    return newCache;
};

/**
 * Return a map of flashcardId: answerSide for when the answerSide is set to both in any learning mode
 * @returns {{[flashcardId]: FlashcardSide}}
 */
export const generateRandomAnswerSideMap = (currFlashcards: Flashcard[]) => {
    return currFlashcards.reduce(
        (accumulator, flashcard) => ({
            // biome-ignore lint: noAccumulatingSpread
            ...accumulator,
            [flashcard.flashcardId]: Math.random() > 0.3 ? FlashcardSide.TERM : FlashcardSide.DEFINITION,
        }),
        {}
    );
};

// just in case the fields where in md format instead of html (old flashcard sets)
export const withHtmlFields = ({ flashcards }: { flashcards: Flashcard[] }): Flashcard[] => {
    return flashcards?.map(flashcard => ({
        ...flashcard,
        term: getHtmlFromContent({ content: flashcard.term ?? "", type: "flashcard" }),
        definition: getHtmlFromContent({ content: flashcard.definition ?? "", type: "flashcard" }),
    }));
};

export const OPPOSITE_SIDE = {
    [FlashcardSide.TERM]: FlashcardSide.DEFINITION,
    [FlashcardSide.DEFINITION]: FlashcardSide.TERM,
};

/**
 * Get the opposite side of a flashcard
 * @param side
 * @returns {string}
 * @example
 * getOppositeFlashcardSide(FlashcardSide.TERM) // => 'DEFINITION'
 * getOppositeFlashcardSide(FlashcardSide.DEFINITION) // => 'TERM'
 */
export const getOppositeFlashcardSide = (side?: FlashcardSide) => OPPOSITE_SIDE[side];

export const duplicateFlashcardSets = (
    flashcardSetIds: {
        userId: string;
        flashcardSetId: string;
    }[],
    user: UserDetails,
    overrides: Partial<FlashcardSet>
) => {
    return Promise.all(
        flashcardSetIds.map(({ flashcardSetId }) => duplicateFlashcardSet(flashcardSetId, user, overrides))
    );
};
export const duplicateFlashcardSet = async (
    baseFlashcardSetId: string,
    user?: UserDetails,
    overrides: Partial<FlashcardSet> = {}
) => {
    const userId = user?.ID;
    if (!userId) throw new Error("User is not logged in");

    const baseFlashcardSet: FlashcardSet = await fetchFlashcardSet({
        flashcardSetId: baseFlashcardSetId,
    });

    const isOwner = baseFlashcardSet.userId === userId;

    const newFlashcardSetInput = {
        ...objectWithout(
            baseFlashcardSet,
            "views",
            "flashcardSetId",
            "trash",
            "public",
            "password",
            "noteId",
            "classPublic",
            "classId",
            "folderId",
            "mediaId",
            "draft",
            "created",
            "updated",
            "rating",
            "ratingCount",
            "studySettings",
            "autocompleteSettings"
        ),
        title: baseFlashcardSet.title + " (copy)",
        userId,
        schoolId: user.schoolId,
        grade: user.grade,
        ...(isOwner && {
            folderId: baseFlashcardSet.folderId,
            classId: baseFlashcardSet.classId,
        }),
        ...overrides,
        // always make duplicate flashcard sets private
        public: false,
    };

    const newFlashcardSet = await makeStandaloneFlashcardSet(newFlashcardSetInput);
    await addStandaloneFlashcardSetToTheCache(newFlashcardSet);

    if (newFlashcardSet.classId) {
        // TODO: why is this needed?
        mutate(resolveClassSWRKey({ classId: newFlashcardSet.classId }));
    }

    const mixpanel = await platform.analytics.mixpanel();
    mixpanel.track("Flashcard Set - Created", {
        userId,
        flashcardSetId: newFlashcardSet.flashcardSetId,
        classId: newFlashcardSet.classId,
        duplicated: true,
        noteId: newFlashcardSet?.noteId,
        public: newFlashcardSet?.public,
        mediaId: newFlashcardSet?.mediaId,
        xp: user?.xp,
        level: user?.level,
        streak: user?.streak,
        coins: user?.coins,
        gamificationRecords: user?.records,
    });

    return newFlashcardSet;
};

export const getMostRecentClassFlashcardSet = (classFlashcardSets?: Record<string, FlashcardSet> | null) => {
    if (!classFlashcardSets) return null;

    return Object.values(classFlashcardSets || {})
        .filter(flashcardSet => !flashcardSet.draft)
        .sort(
            (a, b) =>
                Number(b?.addedAt || b.updated?.toString() || "0") - Number(a?.addedAt || a.updated?.toString() || "0")
        )?.[0];
};
