import { fetchGetJSON } from "@/fetchFunctions/fetchWrappers";
import { fetchBookmarks } from "@/hooks/bookmarks/utils";
import { callGetClass } from "@/hooks/classes/graphqlUtils";
import { resolveClassSWRKey, updateClass } from "@/hooks/classes/utils";
import { makeStandaloneFlashcardSet, resolveFlashcardSetSWRKey } from "@/hooks/flashcards/utils";
import {
    callDeleteMedia,
    callDuplicateMedia,
    callEditMediaChapter,
    callGenerateMediaTranscription,
    callGetMedia,
    callUpdateMedia,
} from "@/hooks/media/graphqlUtils";
import { createNewNote, resolveNoteSWRKey } from "@/hooks/notes/utils";
import { platform } from "@/platform";
import { Transcript, Utterance } from "@/types/common";
import { objectWithout } from "@/utils/dataCleaning";
import { now, parseStringTimestampToFloat, secondsToLargerUnit } from "@/utils/dateTimeUtils";
import { fileTypeFromMimeType } from "@/utils/fileTypeUtils";
import { fromEntries, retry, wait } from "@/utils/genericUtils";
import { uploadMediaToS3 } from "@/utils/s3";
import { UploadDataOutput } from "@aws-amplify/storage";
import {
    Class,
    Flashcard,
    FlashcardSet,
    Media,
    MediaChapterInput,
    MediaType,
    Note,
    UserDetails,
} from "@knowt/syncing/graphql/schema";
import { Dispatch, SetStateAction } from "react";
import { mutate } from "swr";
import { v4 as uuidv4 } from "uuid";
import { StableSWRKeys, getSWRValue, safeLocalMutate } from "../swr/swr";
import { AV_BUCKET, PDF_BUCKET } from "./constants";

type MediaMetadataMap = Record<string, Media>;

export const resolveMediaSWRKey = ({ mediaId, isEnabled = true }: { mediaId?: string; isEnabled?: boolean }) => {
    return isEnabled && mediaId ? ["media", mediaId] : null;
};

const getStaticUrlSection = ({ media }: { media: Media }) => {
    const bucket = media?.bucket;
    const mediaId = media?.mediaId;

    return `https://${bucket}.s3.amazonaws.com/${mediaId}`;
};

export const getStaticS3Url = ({ mediaId, bucket }: { mediaId: string; bucket: string }) => {
    return `https://${bucket}.s3.amazonaws.com/${mediaId}`;
};

export const resolveMediasSWRKey = ({ userId, isEnabled = true }: { userId: string; isEnabled?: boolean }) =>
    isEnabled && userId && ["medias", userId];

export const resolveFolderMediasSWRKey = ({ folderId, isEnabled = true }: { folderId: string; isEnabled?: boolean }) =>
    isEnabled && folderId && ["folder-medias", folderId];

export const resolveClassMediasSWRKey = ({ classId, isEnabled = true }: { classId: string; isEnabled?: boolean }) =>
    isEnabled && classId && ["class-medias", classId];

export const resolveMediaChaptersSWRKey = ({ media }: { media: Media }) => {
    const chaptersUrl = media && [MediaType.AUDIO, MediaType.VIDEO].includes(media.type) && getChaptersUrl({ media });
    return ["media-chapters", chaptersUrl];
};

export const getMediaUrl = ({ media }: { media: Media }) => {
    const fileType = media?.fileType?.toLowerCase();

    return `${getStaticUrlSection({ media })}.${fileType}`;
};

export const getSpriteSheetVTTUrl = ({ media }: { media: Media }) => {
    return `${getStaticUrlSection({ media })}-spritesheet.vtt`;
};

export const getTranscriptUrl = ({ media, preview }: { media: Media; preview?: boolean }) => {
    if (preview) {
        return `${getStaticUrlSection({ media })}-preview.json`;
    }

    return `${getStaticUrlSection({ media })}-transcription.json`;
};

export const getUtterancesUrl = ({ media }: { media: Media }) => {
    return `${getStaticUrlSection({ media })}-utterances.json`;
};

export const getSubtitlesUrl = ({ media }: { media: Media }) => {
    return `${getStaticUrlSection({ media })}-subtitles.vtt`;
};

export const getChaptersUrl = ({ media }: { media: Media }) => {
    return `${getStaticUrlSection({ media })}-chapters.vtt`;
};

export const fetchTranscript = async ({ transcriptUrl }: { transcriptUrl: string }): Promise<Transcript> => {
    if (!transcriptUrl) return null;
    return (await fetchGetJSON(transcriptUrl)).data;
};

export const fetchUtterances = async ({ utterancesUrl }: { utterancesUrl: string }): Promise<Utterance> => {
    if (!utterancesUrl) return null;

    return await retry(
        async () => {
            return (await fetchGetJSON(utterancesUrl)).data;
        },
        12,
        5000
    );
};

export const getLowerResolutionVideoUrl = ({ media, resolution }: { media: Media; resolution: string }) => {
    return `https://${media.bucket}-smaller.s3.amazonaws.com/${media.mediaId}-${resolution}.mp4`;
};

export const convertChaptersVttToObject = (chaptersVtt: string) => {
    if (chaptersVtt.includes("<Code>NoSuchKey</Code>")) return null;

    const lines = chaptersVtt
        .trim()
        .split("\n")
        .filter(line => !line.includes("WEBVTT"));

    const chapters: MediaChapterInput[] = [];
    let currentChapter: MediaChapterInput = { start: null, end: null, title: null };

    for (let i = 0; i < lines.length; i++) {
        const line = lines[i].trim();
        if (line.includes("-->")) {
            //if there are untitled chapters
            if (currentChapter.start !== null && currentChapter.end !== null) {
                chapters.push({ title: null, ...currentChapter });
                currentChapter = { start: null, end: null, title: null };
            }
            const [start, end] = line.split(" --> ");
            // biome-ignore lint/complexity/useLiteralKeys: <explanation>
            currentChapter["start"] = parseStringTimestampToFloat(start);
            // biome-ignore lint/complexity/useLiteralKeys: <explanation>
            currentChapter["end"] = parseStringTimestampToFloat(end);
        } else if (line) {
            // biome-ignore lint/complexity/useLiteralKeys: <explanation>
            currentChapter["title"] = line;
            chapters.push({ ...currentChapter });
            currentChapter = { start: null, end: null, title: null };
        }
    }

    return chapters;
};

export const fetchChapters = async ({ chaptersUrl }: { chaptersUrl: string }): Promise<MediaChapterInput[]> => {
    if (!chaptersUrl || chaptersUrl.includes("undefined")) return null;

    return await retry(
        async () => {
            return await fetch(chaptersUrl).then(async res => convertChaptersVttToObject(await res.text()));
        },
        12,
        5000
    );
};

export const fetchMediaWithRetries = async ({
    maxTry,
    delay,
    mediaId,
    retryCount = 0,
}: {
    maxTry: number;
    delay: number;
    mediaId: string;
    retryCount?: number;
}): Promise<Media> | never => {
    const media = await callGetMedia({ mediaId });
    if (media) return media;

    if (retryCount < maxTry) {
        await wait(delay);
        return await fetchMediaWithRetries({ mediaId, maxTry, delay, retryCount: retryCount + 1 });
    } else {
        throw new Error(`Unable to fetch media after ${maxTry} retries.`);
    }
};

export const waitForMediaEntryCreationOnUpload = async ({ mediaId }) => {
    try {
        // poll for 1 minute
        const media = await fetchMediaWithRetries({ mediaId, maxTry: 60, delay: 1000 });
        return { mediaCreated: true, media };
    } catch {
        return { mediaCreated: false, media: undefined };
    }
};

export const verifyS3Presence = async ({ media }: { media: Media }) => {
    return await retry(
        async () => {
            return fetch(getMediaUrl({ media }));
        },
        12,
        5000
    );
};

export const getIsVideoOrAudio = (extension: string) => {
    return ["mp4", "mov", "m4a", "mkv", "m4a", "mp3", "wav", "ogg", "webm"].includes(extension);
};

export const uploadFile = async ({
    fileBlob,
    userId,
    folderId,
    classId,
    setUploadProgress,
    setUploadRemainingTime,
    setCurrentUploadJob,
    contentType,
    mediaIdToStartWith,
    title,
}: {
    fileBlob: Blob;
    userId?: string;
    folderId?: string;
    classId?: string;
    setUploadProgress: Dispatch<SetStateAction<number>>;
    setCurrentUploadJob: (job: UploadDataOutput) => void;
    setUploadRemainingTime: Dispatch<SetStateAction<string>>;
    contentType: string;
    mediaIdToStartWith?: string;
    title?: string;
}) => {
    try {
        const id = mediaIdToStartWith || uuidv4();
        const fileName = `${id}.${fileTypeFromMimeType(contentType)}`;
        const isVideoOrAudio = getIsVideoOrAudio(fileTypeFromMimeType(contentType));

        await uploadMediaToS3({
            userId,
            folderId,
            classId,
            s3BucketName: isVideoOrAudio ? AV_BUCKET : PDF_BUCKET,
            media: fileBlob,
            setCurrentUploadJob,
            fileName,
            contentType,
            progressCallback: ({ transferredBytes, totalBytes }) => {
                setUploadProgress(Math.round((transferredBytes * 100) / totalBytes));
            },
            remainingTimeCallback: remainingTimeInSeconds => {
                const { value, unit } = secondsToLargerUnit(remainingTimeInSeconds);
                const unitString = unit === "second" ? "s" : unit === "minute" ? "m" : "h";
                setUploadRemainingTime(value + unitString);
            },
            title,
        });

        return {
            id,
            bucket: isVideoOrAudio ? AV_BUCKET : PDF_BUCKET,
            contentType,
            cancelled: false,
        };
    } catch (error) {
        const { report } = await platform.analytics.logging();
        report(error, "uploading_file", { userId, folderId, classId, contentType });
        return { cancelled: true };
    }
};

/**
 * Updates the truncated representation of the media in the media list
 */
export const updateMediaInMediaList = async (newMedia: Media) => {
    const { userId, folderId, classId } = newMedia;

    await mutate(["media", newMedia.mediaId], () => ({ ...newMedia }), { revalidate: false });

    const relatedSWRKeys = [
        resolveMediasSWRKey({ userId }),
        ...(classId ? [resolveClassMediasSWRKey({ classId })] : []),
        ...(folderId ? [resolveFolderMediasSWRKey({ folderId })] : []),
    ];

    await Promise.all(
        relatedSWRKeys.map(swrKey =>
            safeLocalMutate(swrKey, (oldMedias: MediaMetadataMap) => ({
                ...oldMedias,
                [newMedia.mediaId]: newMedia,
            }))
        )
    );
};

const updateCache = (cache: Record<string, Media>, mediaIds: string[], updatedFields: Partial<Media>) => {
    const newCache = { ...cache };
    // biome-ignore lint/complexity/noForEach: <explanation>
    // biome-ignore lint/suspicious/noAssignInExpressions: <explanation>
    mediaIds.forEach(mediaId => (newCache[mediaId] = { ...newCache[mediaId], ...updatedFields }));
    return newCache;
};

const addToCache = (cache: Record<string, Media>, medias: Media[]) => {
    const newCache = { ...cache };
    // biome-ignore lint/suspicious/noAssignInExpressions: <explanation>
    // biome-ignore lint/complexity/noForEach: <explanation>
    medias.forEach(media => (newCache[media.mediaId] = media));
    return newCache;
};

export const trashMedias = async ({
    mediaIds,
    sourceFolderId,
    sourceClassId,
}: {
    mediaIds: {
        userId: string;
        mediaId: string;
    }[];
    sourceFolderId: string | null;
    sourceClassId?: string | null;
}) => {
    await updateMediaTrashState({
        mediaIds,
        sourceFolderId,
        sourceClassId,
        inTrash: true,
        removeFromFolder: true,
        removeFromClass: true,
    });
};

export const restoreMedias = async ({
    mediaIds,
    sourceFolderId,
}: {
    mediaIds: {
        userId: string;
        mediaId: string;
    }[];
    sourceFolderId: string | null;
}) => {
    await updateMediaTrashState({ mediaIds, sourceFolderId, inTrash: false, removeFromFolder: true });
};

const updateMediaTrashState = async ({
    mediaIds,
    sourceFolderId,
    sourceClassId,
    inTrash,
    removeFromFolder,
    removeFromClass,
}: {
    mediaIds: {
        userId: string;
        mediaId: string;
    }[];
    sourceFolderId: string | null;
    sourceClassId?: string | null;
    inTrash: boolean;
    removeFromFolder: boolean;
    removeFromClass?: boolean;
}) => {
    await genericUpdateMedias({
        mediaIds,
        sourceFolderId,
        destinationFolderId: removeFromFolder ? null : sourceFolderId,
        sourceClassId,
        updatedFields: {
            trash: inTrash,
            ...(removeFromFolder && { folderId: null }),
            ...(removeFromClass && { classId: null }),
        },
    });
};

export const moveMedias = async ({
    mediaIds,
    sourceFolderId = null,
    destinationFolderId,
    sourceClassId,
    destinationClassId,
    isPublic,
    sharedSections,
}: {
    mediaIds: {
        userId: string;
        mediaId: string;
    }[];
    sourceFolderId: string | null | undefined;
    destinationFolderId: string | null | undefined;
    sourceClassId?: string | null | undefined;
    destinationClassId?: string | null | undefined;
    isPublic?: boolean;
    sharedSections?: string[] | null;
}) => {
    await genericUpdateMedias({
        mediaIds,
        sourceFolderId,
        destinationFolderId,
        sourceClassId,
        destinationClassId,
        updatedFields: {
            folderId: destinationFolderId,
            classId: destinationClassId,
            sections: sharedSections,
            ...(isPublic === undefined ? {} : { public: isPublic }),
            ...(sourceClassId !== destinationClassId ? { addedAt: now().toString() } : {}),
            ...(destinationClassId ? { password: null } : {}),
        },
    });
};

export const genericUpdateMedias = async ({
    mediaIds,
    updatedFields,
    sourceFolderId = null,
    destinationFolderId = null,
    sourceClassId = null,
    destinationClassId = null,
}: {
    mediaIds: {
        userId: string;
        mediaId: string;
    }[];
    updatedFields: Partial<Media>;
    sourceFolderId?: string | null;
    destinationFolderId?: string | null;
    sourceClassId?: string | null;
    destinationClassId?: string | null;
}) => {
    const currentUser = await getSWRValue(StableSWRKeys.USER);
    const currentUserId = currentUser.user.ID;

    await Promise.all([
        mutate(
            resolveFolderMediasSWRKey({ folderId: sourceFolderId }),
            async oldMedias =>
                fromEntries(
                    Object.values(
                        updateCache(
                            oldMedias,
                            mediaIds.map(m => m.mediaId),
                            updatedFields
                        )
                    )
                        .filter(media => media.folderId === sourceFolderId)
                        .map(media => [media.mediaId, media])
                ),

            { revalidate: false }
        ),
        mutate(
            resolveClassMediasSWRKey({ classId: sourceClassId }),
            async oldMedias =>
                updateCache(
                    oldMedias,
                    mediaIds.map(m => m.mediaId),
                    updatedFields
                ),
            { revalidate: false }
        ),
        mutate(
            resolveMediasSWRKey({ userId: currentUserId }),
            async oldMedias =>
                updateCache(
                    oldMedias,
                    mediaIds.map(m => m.mediaId),
                    updatedFields
                ),
            { revalidate: false }
        ),
    ]);

    try {
        const updatedMedias = await Promise.all(
            mediaIds.map(({ userId, mediaId }) => saveMedia({ mediaId, userId, updates: updatedFields }))
        );

        if (sourceFolderId !== destinationFolderId) {
            await mutate(
                resolveFolderMediasSWRKey({ folderId: destinationFolderId }),
                oldMedias => addToCache(oldMedias, updatedMedias),
                { revalidate: false }
            );
        }

        if (sourceClassId !== destinationClassId) {
            await mutate(
                resolveClassMediasSWRKey({ classId: destinationClassId }),
                oldMedias => addToCache(oldMedias, updatedMedias),
                { revalidate: false }
            );

            if (sourceClassId) {
                const sourceClass =
                    (await mutate(resolveClassSWRKey({ classId: sourceClassId }), (oldData: Class) => oldData, {
                        revalidate: false,
                    })) ?? (await callGetClass({ classId: sourceClassId }));

                const updatedPinned = sourceClass.pinned.filter(
                    itemId => !mediaIds.find(({ mediaId }) => itemId === mediaId)
                );

                if (updatedPinned.length !== sourceClass.pinned.length) {
                    await updateClass({ classId: sourceClassId, pinned: updatedPinned }, { ID: currentUserId });
                }
            }

            if (destinationClassId) {
                const bookmarks = await fetchBookmarks({ userId: currentUserId });

                const bookmarkedMedias = mediaIds
                    .filter(({ mediaId }) => bookmarks.some(b => b.ID === mediaId))
                    .map(({ mediaId }) => mediaId);

                if (bookmarkedMedias.length) {
                    const destinationClass =
                        (await mutate(
                            resolveClassSWRKey({ classId: destinationClassId }),
                            (oldData: Class) => oldData,
                            {
                                revalidate: false,
                            }
                        )) ?? (await callGetClass({ classId: destinationClassId }));

                    const updatedPinned = [...destinationClass.pinned, ...bookmarkedMedias];
                    await updateClass({ classId: destinationClassId, pinned: updatedPinned }, { ID: currentUserId });
                }
            }
        }
    } catch {
        const toast = await platform.toast();
        toast.error("Failed to move medias. Please refresh the page.");
    }
};

const saveMedia = async ({
    mediaId,
    userId,
    updates: _updates,
}: {
    mediaId: string;
    userId: string;
    updates: Partial<Media>;
}) => {
    const updates = { ..._updates, updated: now().toString() };

    const updatedMedia = await callUpdateMedia({ mediaId, userId, mediaInput: updates });
    await mutate(["media", mediaId], updatedMedia, { revalidate: false });

    await syncAttachedItemsCacheIfNeeded(updates, updatedMedia);

    return updatedMedia;
};

const syncAttachedItemsCacheIfNeeded = async (updates: Partial<Media>, updatedMedia: Media) => {
    const willBackendUpdateAttachedItems = ["public, password"].some(field => updates[field] !== undefined);

    if (willBackendUpdateAttachedItems) {
        const { userId, noteId, flashcardSetId } = updatedMedia;
        if (noteId) await mutate(resolveNoteSWRKey({ noteId, userId }));
        if (flashcardSetId) await mutate(resolveFlashcardSetSWRKey({ flashcardSetId }));
    }
};

export const deleteMedias = async ({
    mediaIds,
    folderId = null,
}: {
    mediaIds: {
        userId: string;
        mediaId: string;
    }[];
    folderId?: string | undefined;
}) => {
    await mutate(
        resolveFolderMediasSWRKey({ folderId }),
        oldMedias => objectWithout(oldMedias, ...mediaIds.map(m => m.mediaId)),
        {
            revalidate: false,
        }
    );

    try {
        await Promise.all(mediaIds.map(({ userId, mediaId }) => callDeleteMedia({ mediaId, userId })));
    } catch {
        const toast = await platform.toast();
        toast.error("Failed to delete medias. Please refresh the page.");
    }
};

const revalidateMediaChapters = async ({ media }: { media: Media }) => {
    await mutate(resolveMediaChaptersSWRKey({ media }));
};

export const editMediaChapter = async ({ mediaId, chapterIndex, chapterTitle }) => {
    const media = await callGetMedia({ mediaId: mediaId });

    await mutate(
        resolveMediaChaptersSWRKey({ media }),
        oldChapters => {
            const newChapters = [...oldChapters];
            newChapters[chapterIndex] = { ...newChapters[chapterIndex], title: chapterTitle };
            return newChapters;
        },
        { revalidate: false }
    );

    try {
        await callEditMediaChapter({ mediaId, chapterIndex, chapterTitle });
    } catch {
        await revalidateMediaChapters({ media });
    }
};

export const createMediaNote = async ({
    media,
    user,
    overrides,
}: {
    media: Media;
    user: UserDetails | undefined;
    overrides?: Partial<Note>;
    ignoreMediaUpdate?: boolean;
}) => {
    if (!user) throw new Error("Not Logged in");
    if (media.noteId) throw new Error("note already exists");

    const newNoteId = uuidv4();

    const note = await createNewNote(
        {
            noteId: newNoteId,
            mediaId: media.mediaId,
            public: media.public,
            password: media.password,
            folderId: media.folderId,
            classId: media.classId,
            flashcardSetId: media.flashcardSetId,
            draft: true,
            ...overrides,
        },
        user
    );

    const newMedia = { ...media, noteId: newNoteId, title: media.title || overrides?.title };
    await updateMediaInMediaList(newMedia);

    const mixpanel = await platform.analytics.mixpanel();
    mixpanel.track("Note - Created", {
        mediaId: media.mediaId,
        classId: media.classId,
        flashcardSetId: media.flashcardSetId,
        noteId: newNoteId,
        mediaType: media.type,
        importType: note?.importType,
        xp: user?.xp,
        level: user?.level,
        streak: user?.streak,
        coins: user?.coins,
        gamificationRecords: user?.records,
    });

    return await callUpdateMedia({
        mediaId: media.mediaId,
        userId: user.ID,
        mediaInput: {
            noteId: newNoteId,
            title: media.title || overrides?.title,
        },
    }).catch(async error => {
        await mutate(["media", media.mediaId]);
        throw error;
    });
};

export const createMediaFlashcardSet = async ({
    media,
    flashcards,
    user,
    overrides,
}: {
    media: Media;
    flashcards?: Flashcard[];
    user: UserDetails | undefined;
    overrides: Partial<FlashcardSet>;
}) => {
    if (!user) throw new Error("Not Logged in");
    if (media.flashcardSetId) throw new Error("flashcard set already exists");

    const { flashcardSetId } = await makeStandaloneFlashcardSet({
        userId: user.ID,
        flashcards: flashcards,
        title: media.title,
        folderId: media.folderId,
        classId: media.classId,
        mediaId: media.mediaId,
        public: media.public,
        password: media.password,
        noteId: media.noteId,
        draft: true,
        ...overrides,
    });

    const newMedia = { ...media, flashcardSetId, title: media.title || overrides?.title };
    await updateMediaInMediaList(newMedia);

    const mixpanel = await platform.analytics.mixpanel();
    mixpanel.track("Flashcard Set - Created", {
        mediaId: media.mediaId,
        flashcardSetId,
        classId: media.classId,
        noteId: media.noteId,
        mediaType: media.type,
        public: media.public,
        xp: user?.xp,
        level: user?.level,
        streak: user?.streak,
        coins: user?.coins,
        gamificationRecords: user?.records,
    });

    return await callUpdateMedia({
        mediaId: media.mediaId,
        userId: user.ID,
        mediaInput: {
            flashcardSetId,
            title: media.title || overrides?.title,
        },
    }).catch(async error => {
        await mutate(["media", media.mediaId]);
        throw error;
    });
};

export const createMediaTranscript = async ({ mediaId }) => {
    await callGenerateMediaTranscription({ mediaId });

    //update cache
    await mutate(
        ["media", mediaId],
        oldData => ({
            ...oldData,
            updated: now().toString(),
            //so we show the generating indicators
            transcript: null,
        }),
        { revalidate: false }
    ); // Set the optimistic data
};

export const duplicateMedias = async (
    mediaIds: {
        userId: string;
        mediaId: string;
    }[],
    userId: string,
    folderId?: string
) => {
    await Promise.all(mediaIds.map(({ mediaId }) => duplicateMedia({ baseMediaId: mediaId, userId, folderId })));
};

export const duplicateMedia = async ({
    baseMediaId,
    userId,
    folderId,
    overrides,
}: {
    baseMediaId: string;
    userId: string;
    folderId?: string;
    overrides?: Partial<Media>;
}) => {
    const baseMedia = await callGetMedia({ mediaId: baseMediaId });
    const isOwner = baseMedia.userId === userId;

    const newMediaInput = {
        ...objectWithout(baseMedia, "mediaId", "flashcardSetId", "noteId", "rating", "classId", "folderId"),
        mediaId: uuidv4(),
        title: baseMedia.title + " (copy)",
        userId,
        created: now().toString(),
        updated: now().toString(),
        public: baseMedia.public,
        ...(isOwner && { folderId: baseMedia.folderId, classId: baseMedia.classId }),
        ...overrides,
    };

    await updateMediaInMediaList(newMediaInput);
    await mutate(["media", newMediaInput.mediaId], newMediaInput, { revalidate: false });

    return callDuplicateMedia({ baseMediaId, newMediaId: newMediaInput.mediaId, folderId });
};

export const getContentForSelectedPdfPages = (newSelectedPDFPages: number[], transcript: Transcript) => {
    const uniquePages = [...new Set(newSelectedPDFPages)].sort((a, b) => a - b);

    if (transcript === undefined || (transcript && transcript?.length === 0)) {
        return;
    }

    const selectedPDFPageContentObjects = uniquePages.map(page => transcript[page - 1]);

    const stringifiedSelectedPDFPageContent = selectedPDFPageContentObjects
        ?.map(({ content }, index) => `\nPage ${uniquePages[index]}:\n${content}\n`)
        .join("\n")
        .trim();

    return stringifiedSelectedPDFPageContent;
};
